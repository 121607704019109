
import { Options, Vue } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import ContentLogsViewNew from "./components/content-logs-view-new.vue";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";

@Options<ContentLogsViewErrorsPage>({
  components: {
    Page,
    LogsViewFilter,
    ContentLogsViewNew,
  },
})
export default class ContentLogsViewErrorsPage extends Vue {
  readonly slotProps = {};
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();
  filterModelView: FilterModel = null;
  getUtc = getUtc;

  onclickFilterChange() {
    this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
    this.filterModelView.LogLevel = EnumLogLevel.Error;
    (this.$refs.page as ContentLogsViewNew).refresh(this.filterModelView);
  }
  mounted() {
    // this.onclickFilterChange();
  }
  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
  //var date = convertUTCDateToLocalDate(new Date(date_string_you_received));
}
